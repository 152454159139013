import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Talks = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container talks">
        <SEO title="Talks" />

        <div className="talks-entry">
          <div className="talks-header">Embedded Programming for Quadcopters</div>
          <div className="talks-links">
            February 18, 2015&nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="http://www.slideshare.net/ryanboland18/quad-stripped"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Slides
            </a>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="https://github.com/bolandrm/rmb_multicopter"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Project Code
            </a>
          </div>
          <div className="talks-video-wrapper">
            <iframe
              title="embedded-programming-for-quadcopters-video"
              allowFullScreen
              frameBorder="0"
              height="315"
              width="560"
              src="https://www.youtube.com/embed/CHSYgLfhwUo"
            />
          </div>
          <div className="talks-description">
            In this talk I discuss my scratch-built and scratch-coded quadcopter project. I cover
            quadcopter basics, how to interpret and utilize sensor data, some of the code's
            implementation details, and finally safety.
          </div>
        </div>

        <div className="talks-entry">
          <div className="talks-header">Making with Arduino Workshop</div>
          <div className="talks-links">
            September 26, 2015&nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="https://github.com/bolandrm/arduino_workshop/blob/master/slides.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Slides
            </a>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="https://github.com/bolandrm/arduino_workshop"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Project Materials & Samples
            </a>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <Link to="/blog/arduino-workshop" className="standard-link">
              Blog Post
            </Link>
          </div>

          <div className="talks-image-wrapper">
            <Image fluid={data.arduinoWorkshopImg.childImageSharp.fluid} className="talks-image" />
          </div>
          <div className="talks-description">
            This workshop covered the basics of getting started with Arduino. Everyone got an
            Arduino and electronics kit, and we covered the basics of circuits & embedded
            programming.
          </div>
        </div>

        <div className="talks-entry">
          <div className="talks-header">Lessons from a year of building apps with React Native</div>
          <div className="talks-links">
            October 23, 2018&nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="https://www.slideshare.net/ryanboland18/lessons-from-a-year-of-building-apps-with-react-native"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Slides
            </a>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <a
              href="https://www.pscp.tv/codeandsupply/1jMJgERaNNjKL"
              target="_blank"
              rel="noopener noreferrer"
              className="standard-link"
            >
              Video
            </a>
          </div>
          <div className="talks-description">
            React Native is a framework for developing native iOS and Android applications with
            JavaScript & React. Unlike some other JavaScript app development frameworks, React
            Native uses real native components for a completely native look and feel. In this
            presentation, I give an overview of the framework, as well as some tips on topics like
            testing, continuous integration, and code structure to get you up and running quickly.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Talks

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    arduinoWorkshopImg: file(relativePath: { eq: "arduino-workshop.png" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
